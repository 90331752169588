<template>
    <div class="content-wrap">
        <div class="success">
            <img src="@/assets/icon/success@2x.png"></img>
            <div class="success-text">
                <span>提交成功，请等待审核</span>
            </div>
            <el-button type="primary" @click="handleGoBackPersonalCenter">返回组织管理</el-button>
            <div class="success-box">
                <div class="box-top">
                    <span>组织信息</span>
                </div>
                <div class="box-middle">
                    <span>组织名称：{{organization.name}}</span>
                    <span>税号：{{organization.taxNo}}</span>
                </div>
                <div class="process">
                    <div>
                        <div class="spot-line" style="margin-left: 20px">
                            <div class="spot"></div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <span>填写资料</span>
                        </div>
                    </div>
                    <div>
                        <div class="spot-line">
                            <div class="spot"></div>
                            <div class="line" style="background-color: #E8E8E8"></div>
                        </div>
                        <div style="margin-left: -20px">
                            <span>平台审核</span>
                        </div>
                    </div>
                    <div>
                        <div class="spot-line">
                            <div class="spot" style="background-color: #E8E8E8"></div>
                        </div>
                        <div style="margin-left: -20px;color: #E8E8E8">
                            <span>审核成功</span>
                        </div>
                    </div>
                </div>
                <div>{{organization.created}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "success",
        data() {
            return {
                organization: {}
            }
        },
        created() {
            this.organization = this.$route.query.organization;
        },
        methods: {
            handleGoBackPersonalCenter() {
                this.$router.push("/system/organization")
            }
        }
    }
</script>

<style lang="scss" scoped>
    .success {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #fff;
        margin-bottom: 24px;

        img {
            width: 72px;
            height: 72px;
            margin-top: 110px;
        }

        .success-text {
            width: 240px;
            height: 32px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 32px;
            margin-top: 12px;
        }

        .el-button {
            width: 140px;
            height: 32px;
            background: rgba(61, 148, 255, 1);
            border-radius: 4px;
            margin-top: 24px;
        }

        .success-box {
            width: 1028px;
            height: 232px;
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin-top: 48px;
            margin-bottom: 223px;
            padding: 24px 40px;

            .box-top {
                width: 64px;
                height: 24px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 24px;
                margin-bottom: 20px;
            }

            .box-middle {
                display: flex;
                margin-bottom: 20px;

                span {
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 22px;
                }

                :last-child {
                    margin-left: 100px;
                }
            }

            .process {
                display: flex;
                margin-bottom: 20px;

                .spot-line {
                    display: flex;
                    margin-bottom: 14px;
                    align-items: center;


                    .spot {
                        width: 8px;
                        height: 8px;
                        background: rgba(24, 144, 255, 1);
                        border: 3px solid rgba(250, 250, 250, 1);
                        border-radius: 50%;
                    }

                    .line {
                        width: 446px;
                        height: 2px;
                        background: rgba(24, 144, 255, 1);
                        border-radius: 6px;
                    }
                }
            }

        }
    }
</style>
